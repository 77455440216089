import classNames from "classnames";
import { useEffect, useState } from "react";
import { ProductItemInterface } from "../../../assets/interfaces";
import { toAppUrl, toCurrency } from "../../../assets/js/utils/functions";
import { getDiscountPercentage, getProductOptionsLength } from "../../../assets/js/utils/utils";
import CartContext from "../../../contexts/cart-context";
import useAnalytics, { PAGE_TYPE } from "../../hooks/analytics";
import useCopyClipboard from "../../hooks/useCopyClipboard";
import { useModals } from "../../hooks/useModals";
import Portal from "../../portal";
import ImageCarouselModal from "../../products/image-carousel-modal";
import { ProductImages } from "../../products/modals/product-details";
import Badge from "../../ui/badge";
import { AppBtn } from "../../ui/buttons";
import Modal, { ModalBody, ModalFooter } from "../../ui/modal";
import { DiscountBadge } from "../items/bages";

//consider options that are not available

interface Props {
  show: boolean;
  toggle: (state: boolean) => void;
  item: ProductItemInterface;
  itemPage?: boolean;
}

const ProductDetailsModal: React.FC<Props> = ({ show, toggle, item, itemPage, children }) => {
  const { modals, toggleModal } = useModals(["image_carousel"]);
  const [currentImage, setCurrentImage] = useState(0);
  const {
    addOrRemoveItem,
    isItemInCart,
    cart,
    toggleModal: toggleModalFromCart,
    removeItem,
    formatAsCurrency,
  } = CartContext.useContainer();
  const [copied, copy] = useCopyClipboard(toAppUrl(`products/${item?.slug}`), {
    successDuration: 1000,
  });
  const { saveAnalytics } = useAnalytics(PAGE_TYPE.ITEM);
  const discountExists = item.discount_price !== undefined && item.discount_price > 0;

  const cartItem = cart.find(({ item_id }) => item_id === item.id);

  useEffect(() => {
    if (show) {
      saveAnalytics({ store: item.store, item: item.id, itemName: item.name });
    }
  }, [show]);

  const showFullImage = (index: number) => {
    setCurrentImage(index);
    toggleModal("image_carousel");
  };

  const handleItemPageSelection = () => {
    if (cart.length < 1 || item?.variants?.options.length > 0) {
      addOrRemoveItem(item);
    } else {
      toggleModalFromCart("cart");
    }
  };

  return (
    <>
      <Modal {...{ show, toggle }} title={item.name} closeable={!itemPage} noBg={itemPage}>
        <ModalBody>
          {/* <h4 className="text-black text-xl sm:text-2lg font-bold mb-3.75 sm:mb-5">{item.name}</h4> */}
          <div className="-mx-5 sm:-mx-6.25 overflow-hidden mb-3.75 border-b border-grey-divider pb-4">
            <div
              className="grid gap-3 overflow-x-auto product-images-grid"
              style={{ "--cols": `${item?.images.length}` } as React.CSSProperties}
              // style={{ gridTemplateColumns: `10px repeat(${item?.images.length || 5}, 25%) 10px` }}
            >
              <figure className="w-5"></figure>
              <ProductImages {...{ images: item?.images, showFullImage }} />
              <figure className="w-5"></figure>
            </div>
          </div>
          <div className="mb-4 space-y-3.5">
            {(item?.quantity || item.is_always_available) && item?.available && (
              <div className="flex">
                <Badge
                  text={
                    item.is_always_available
                      ? "In Stock"
                      : item?.quantity > 0
                      ? `${item.quantity} in stock`
                      : "Out of stock"
                  }
                  color={item?.quantity > 0 || item.is_always_available ? "green" : "red"}
                />
              </div>
            )}
            <div className="flex items-center justify-between mb-2.5">
              <div
                className={classNames("text-1xs sm:text-sm text-grey-subtext", {
                  "text-opacity-100": item.available,
                  "text-opacity-70 font-medium": !item.available,
                  "flex items-center": !discountExists,
                })}
              >
                {discountExists && (
                  <div
                    className={`text-black font-semibold text-1sm sm:text-base ${
                      ""
                      // item.available ? "text-opacity-100" : "text-opacity-70"
                    }`}
                  >
                    {formatAsCurrency(item.discount_price)}
                  </div>
                )}
                <div
                  className={classNames({
                    // "text-opacity-100": item.available,
                    // "text-opactity-70": !item.available,
                    "line-through text-xs sm:text-1xs": discountExists,
                    "text-black font-semibold text-1sm sm:text-base -mb-2": !discountExists,
                  })}
                >
                  {formatAsCurrency(item.price)}
                </div>
              </div>
              {discountExists && item?.available && (
                // <div className="bg-accent-red-300 rounded-lg px-2 py-1.25 sm:px-2.5 sm:py-1.75 text-[9px] sm:text-xxs text-white font-semibold">
                //   {getDiscountPercentage(item)}% OFF
                // </div>
                <DiscountBadge percentage={getDiscountPercentage(item)} />
              )}

              {!item.available && <Badge text="Unavailable" color="red" />}
            </div>
            {item?.variants?.options.length > 0 && (
              <div className="w-full bg-grey-fields-100 rounded-5 flex items-center text-dark text-1xs py-2.5 px-4 justify-between">
                <div className="inline-flex items-center">
                  {/* prettier-ignore */}
                  <svg width="14" viewBox="0 0 12 12" fill="none">
                    <path d="M5 1.5H1.5V5H5V1.5Z" stroke="#737576" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.5001 1.5H7.00009V5H10.5001V1.5Z" stroke="#737576" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.5001 7H7.00009V10.5H10.5001V7Z" stroke="#737576" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5 7H1.5V10.5H5V7Z" stroke="#737576" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <span className="inline-block ml-2.5 font-medium">
                    {getProductOptionsLength(item?.variants)} options available
                  </span>
                </div>

                <button
                  className="no-outline text-primary-500 flex items-center text-1xs font-semibold"
                  onClick={() => addOrRemoveItem(item)}
                >
                  <span className="">See Options</span>
                  {/* prettier-ignore */}
                  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" className="ml-0.75">
                    <path d="M1 7L7 1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2}/>
                    <path d="M1 1H7V7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2}/>
                  </svg>
                </button>
              </div>
            )}
          </div>
          <dl className="flex flex-col space-y-3.5 pt-3.75 border-t border-grey-divider">
            <div className="flex w-full justify-between items-center">
              <dt className="text-1xs sm:text-sm text-dark">Product Link:</dt>
              <dd className="inline-flex items-center -mt-1" onClick={() => copy()}>
                <span className="text-1xs sm:text-sm font-medium text-primary-500 max-w-[150px] lg:max-w-[220px] overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer">
                  {copied ? "Copied!" : toAppUrl(`products/${item?.slug}`, false)}
                </span>
                <button
                  type="button"
                  className="h-5 w-5 rounded-5 bg-grey-fields-100 flex items-center justify-center ml-1 text-primary-500"
                >
                  {/* prettier-ignore */}
                  <svg width="12" viewBox="0 0 10 10" fill="none">
                    <path d="M8.33333 3.75H4.58333C4.1231 3.75 3.75 4.1231 3.75 4.58333V8.33333C3.75 8.79357 4.1231 9.16667 4.58333 9.16667H8.33333C8.79357 9.16667 9.16667 8.79357 9.16667 8.33333V4.58333C9.16667 4.1231 8.79357 3.75 8.33333 3.75Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2.08337 6.25016H1.66671C1.44569 6.25016 1.23373 6.16237 1.07745 6.00608C0.921171 5.8498 0.833374 5.63784 0.833374 5.41683V1.66683C0.833374 1.44582 0.921171 1.23385 1.07745 1.07757C1.23373 0.921293 1.44569 0.833496 1.66671 0.833496H5.41671C5.63772 0.833496 5.84968 0.921293 6.00596 1.07757C6.16224 1.23385 6.25004 1.44582 6.25004 1.66683V2.0835" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </dd>
            </div>
            {item?.category && (
              <div className="flex w-full justify-between items-center">
                <dt className="text-1xs sm:text-sm text-dark">Product Category:</dt>
                <dd className={`no-outline inline-flex items-center rounded-full flex-shrink-0`}>
                  {item.category?.emoji && (
                    <span className="shadow-sm rounded-full w-6 h-6 p-0.5 text-1xs sm:text-sm flex items-center justify-center bg-white mr-1.25">
                      {item.category?.emoji}
                    </span>
                  )}
                  <span className="inline-block text-black-secondary font-medium text-1xs sm:text-sm">
                    {item.category?.name}
                  </span>
                </dd>
              </div>
            )}
            <div className="flex w-full flex-col space-y-1.5 pt-2.5 !mt-3.5 border-t border-grey-border border-opacity-50">
              <h4 className="text-1sm sm:text-base text-black font-display font-bold">Description:</h4>
              <dd className="text-black-secondary text-1xs sm:text-sm whitespace-pre-line">{item?.description}</dd>
            </div>
          </dl>
        </ModalBody>
        {item.available && !itemPage && (
          <ModalFooter>
            {isItemInCart(item?.id) && cartItem ? (
              <div className="flex items-center w-full justify-between space-x-3">
                <AppBtn
                  color="neutral"
                  className="!text-accent-red-500 flex-1"
                  isBlock
                  onClick={() => addOrRemoveItem(item)}
                >
                  Remove from cart
                </AppBtn>
                <AppBtn className="flex-1" size="md" onClick={() => toggleModalFromCart("cart")}>
                  Open Cart
                </AppBtn>
              </div>
            ) : (
              <AppBtn isBlock onClick={() => addOrRemoveItem(item)}>
                Add To Cart
              </AppBtn>
            )}
          </ModalFooter>
        )}

        {itemPage && (
          <ModalFooter>
            <div className="flex-1 w-full">
              {children}
              {item.available_to_purchase && (
                <div className="mt-2.5">
                  <AppBtn isBlock onClick={handleItemPageSelection}>
                    Buy {item?.name}
                  </AppBtn>
                </div>
              )}
            </div>
          </ModalFooter>
        )}
      </Modal>
      {item?.images && (
        <Portal>
          <ImageCarouselModal
            show={modals.image_carousel.show}
            toggle={() => toggleModal("image_carousel")}
            images={item?.images}
            current={currentImage}
          />
        </Portal>
      )}
    </>
  );
};

export default ProductDetailsModal;
