import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter, toCurrency } from "../../assets/js/utils/functions";
import CartContext from "../../contexts/cart-context";
import { useModals } from "../hooks/useModals";
import LazyImage from "../lazy-image";
import ClearSearch from "../clear-search";
import ProductDetailsModal from "./modals/product-details";
import { EmptyItems } from "./errors";
import { getDiscountPercentage, getProductOptionsLength } from "../../assets/js/utils/utils";
import classNames from "classnames";
import { ProductItemInterface } from "../../assets/interfaces";
import useStoreItemsPagination from "../hooks/useStoreItemsManager";
import AddToCartButton from "./items/add-to-cart-btn";
import { DiscountBadge } from "./items/bages";

interface Props {
  productsLoading: boolean;
  items: { main: ProductItemInterface[]; featured: ProductItemInterface[] };
  search?: string;
  currentPage: number;
  pagData: { next_page: number | null; prev_page: number | null; total_pages: number | null };
  goNext: () => void;
  setSelectedItem: (item: ProductItemInterface) => void;
  isLoading: boolean;
  resetPage: () => void;
}

const ItemsGridView: React.FC<Props> = ({
  productsLoading,
  items,
  search,
  currentPage,
  pagData,
  goNext,
  setSelectedItem,
  isLoading,
  resetPage,
}) => {
  useStoreItemsPagination({ currentPage, pagData, isLoading, goNext });
  const { main, featured } = items;

  return (
    <div className="mt-9">
      <ClearSearch search={search} onClear={resetPage} />
      {featured && featured?.length > 0 && (
        <div className="pb-7.5 mb-7.5 sm:pb-10 sm:mb-10 lg:pb-12.5 lg:mb-12.5 border-b border-grey-border border-opacity-50">
          <h3 className="heading-five font-bold mb-5 sm:mb-6.25 lg:mb-7.5 flex items-center text-grey-outline">
            Featured Items
            {/* prettier-ignore */}
            <svg className="w-5 sm:w-6 ml-1 md:w-6.25 md:ml-1.25 lg:w-8 lg:ml-1.5 mt-1" viewBox="0 0 24 24" fill="none" >
              <path d="M19.8305 8.6998L15.3005 4.1698C14.3505 3.2198 13.0405 2.7098 11.7005 2.7798L6.70046 3.0198C4.70046 3.1098 3.11046 4.6998 3.01046 6.6898L2.77046 11.6898C2.71046 13.0298 3.21046 14.3398 4.16046 15.2898L8.69046 19.8198C10.5505 21.6798 13.5705 21.6798 15.4405 19.8198L19.8305 15.4298C21.7005 13.5798 21.7005 10.5598 19.8305 8.6998ZM9.50046 12.3798C7.91046 12.3798 6.62046 11.0898 6.62046 9.4998C6.62046 7.9098 7.91046 6.6198 9.50046 6.6198C11.0905 6.6198 12.3805 7.9098 12.3805 9.4998C12.3805 11.0898 11.0905 12.3798 9.50046 12.3798Z" fill="currentColor"/>
            </svg>
          </h3>
          <div className="grid grid-cols-2 sm:grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-6 sm:gap-7.5  lg:gap-10">
            {featured.map((item, index) => (
              <ProductItem index={index} key={item.id} toggleModal={(modal) => setSelectedItem(item)} item={item} />
            ))}
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 sm:grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-6 sm:gap-7.5  lg:gap-10">
        {items.main &&
          main.length > 0 &&
          main.map((item, index) => (
            <ProductItem index={index} key={item.id} toggleModal={(modal) => setSelectedItem(item)} item={item} />
          ))}
        {productsLoading && Array.from(Array(10).keys()).map((_, index) => <ProductItemLoader key={index} />)}
        {!productsLoading && main.length < 1 && (
          <div className="col-span-full">
            <EmptyItems />
          </div>
        )}
      </div>
    </div>
  );
};

interface ProductItemProps {
  toggleModal: (modal: string) => void;
  index: number;
  item: ProductItemInterface;
}

const ProductItemLoader = () => {
  return (
    <article className="w-full flex flex-col cursor-pointer max-w-[300px]">
      <figure
        className="w-full rounded-15 overflow-hidden bg-grey-loader animate-pulse"
        style={{ paddingTop: "100%" }}
      ></figure>
      <div className="mt-2 flex flex-col justify-between flex-1">
        <h5 className="w-40 h-3.5 bg-grey-loader animate-pulse"></h5>
        <div className="flex w-full items-center justify-between mt-2">
          <span className="bg-grey-loader w-25 h-2.5 animate-pulse"></span>
        </div>
      </div>
    </article>
  );
};

const ProductItem: React.FC<ProductItemProps> = ({ toggleModal, index, item }) => {
  const discountExists = item.discount_price !== undefined && item.discount_price > 0;
  const { addOrRemoveItem, isItemInCart, formatAsCurrency } = CartContext.useContainer();

  const handleItemButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    addOrRemoveItem(item);
  };

  return (
    <article
      key={index}
      className="w-full flex flex-col cursor-pointer relative max-w-[300px]"
      onClick={() => toggleModal("product_details")}
    >
      <figure className="w-full rounded-15 overflow-hidden relative flex-shrink-0" style={{ paddingTop: "100%" }}>
        <LazyImage
          src={item.images[item?.thumbnail]}
          alt={item.name}
          loaderClasses="rounded-15"
          className="h-full w-full object-cover rounded-15 absolute top-0 left-0"
        />
        {discountExists && item?.available_to_purchase && (
          // <div className="bg-discount-badge rounded-lg px-2 py-1.25 sm:px-2.5 sm:py-1.75 text-xxxs sm:text-xxs absolute top-2.5 left-2.5 text-white font-semibold">
          //   {getDiscountPercentage(item)}% OFF
          // </div>
          <DiscountBadge percentage={getDiscountPercentage(item)} className="absolute top-2.5 left-2.5" />
        )}

        {!item?.available_to_purchase && (
          <span className="block w-max rounded-md text-accent-red-500 text-xxxs sm:text-xs bg-grey-fields-100 px-2 py-1 absolute top-2.5 left-2.5 font-semibold">
            Unavailable
          </span>
        )}
      </figure>
      <div className="mt-2.5 flex flex-col justify-start flex-1 overflow-x-hidden">
        <div
          className={classNames("w-full overflow-hidden flex items-center", {
            "mb-2.5": !item.available_to_purchase && !discountExists,
          })}
        >
          <h5
            className={`text-dark font-display font-bold overflow-hidden text-1sm sm:text-base max-w-full whitespace-nowrap overflow-ellipsis mr-2  ${
              item.available_to_purchase ? "text-opacity-100" : "text-opacity-50"
            }`}
          >
            {capitalizeFirstLetter(item.name)}
          </h5>
          {item.available_to_purchase && item?.variants?.options?.length > 0 && (
            <span className="block w-max font-medium rounded-md text-primary-500 text-xxs sm:text-xs bg-grey-fields-100 px-2 py-1 flex-shrink-0">
              {getProductOptionsLength(item?.variants)} options
            </span>
          )}
        </div>
        <div className={`flex w-full items-center justify-between mt-1`}>
          <div
            className={classNames("text-1xs sm:text-sm text-grey-subtext", {
              "text-opacity-100": item.available_to_purchase,
              "text-opacity-70 font-medium": !item.available_to_purchase,
              "flex items-center": !discountExists,
            })}
          >
            {discountExists && (
              <div
                className={`text-black font-semibold text-1xs sm:text-sm ${
                  item.available_to_purchase ? "text-opacity-100" : "text-opacity-70"
                }`}
              >
                {formatAsCurrency(item.discount_price)}
              </div>
            )}
            <div
              className={classNames({
                "text-opacity-100": item.available_to_purchase,
                "text-opactity-70": !item.available_to_purchase,
                "line-through text-xxs sm:text-xs": discountExists,
                "text-black font-semibold text-1xs sm:text-sm": !discountExists,
              })}
            >
              {formatAsCurrency(item.price)}
            </div>
          </div>

          {item.available_to_purchase && (
            <AddToCartButton isInCart={isItemInCart(item.id)} toggle={handleItemButtonClick} />
            // <button
            //   className={`h-8 w-8 sm:h-8.75 sm:w-8.75 rounded-full flex items-center justify-center shadow-accent flex-shrink-0 ${
            //     isItemInCart(item.id) ? "text-white bg-success" : "bg-grey-fields-100 text-add-to-cart"
            //   }`}
            //   onClick={handleItemButtonClick}
            // >
            //   {isItemInCart(item.id) ? (
            //     <svg height="11" viewBox="0 0 11 8" fill="none" className="transform translate-x-[2%]">
            //       <path
            //         d="M10 1L3.8125 7L1 4.27273"
            //         stroke="currentColor"
            //         strokeWidth="1.5"
            //         strokeLinecap="round"
            //         strokeLinejoin="round"
            //       />
            //     </svg>
            //   ) : (
            //     <svg width="18" viewBox="0 0 14 14" fill="none">
            //       <path
            //         d="M7 2.91675V11.0834"
            //         stroke="currentColor"
            //         strokeWidth="1.2"
            //         strokeLinecap="round"
            //         strokeLinejoin="round"
            //       />
            //       <path
            //         d="M2.91675 7H11.0834"
            //         stroke="currentColor"
            //         strokeWidth="1.2"
            //         strokeLinecap="round"
            //         strokeLinejoin="round"
            //       />
            //     </svg>
            //   )}
            // </button>
          )}
        </div>
      </div>
    </article>
  );
};

export default ItemsGridView;
